import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "out_storage" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_m_dialog_content = _resolveComponent("m-dialog-content")
  const _component_a_modal = _resolveComponent("a-modal")
  const _component_m_form = _resolveComponent("m-form")
  const _component_m_table = _resolveComponent("m-table")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      title: _ctx.title,
      "confirm-loading": _ctx.confirmLoading,
      onOk: _cache[1] || (_cache[1] = $event => (_ctx.showDialog = false)),
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => (_ctx.showDialog = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_m_dialog_content, {
          readOnly: true,
          rules: _ctx.rules,
          forms: _ctx.forms,
          formItems: _ctx.tempForms
        }, null, 8, ["rules", "forms", "formItems"])
      ]),
      _: 1
    }, 8, ["title", "confirm-loading", "visible"]),
    _createVNode(_component_m_form, {
      formItems: _ctx.formItems,
      onSearch: _cache[3] || (_cache[3] = $event => ($setup.loadData(1)))
    }, null, 8, ["formItems"]),
    _createVNode(_component_m_table, {
      loading: _ctx.loading,
      columns: _ctx.columns,
      data: _ctx.tableList,
      buttons: _ctx.buttons,
      totals: _ctx.total,
      currentPage: _ctx.currentPage,
      onPageChange: _cache[4] || (_cache[4] = $event => ($setup.loadData($event))),
      onDrawBill: _cache[5] || (_cache[5] = $event => ($setup.drawBill($event))),
      onDetail: _cache[6] || (_cache[6] = $event => ($setup.detail($event)))
    }, null, 8, ["loading", "columns", "data", "buttons", "totals", "currentPage"])
  ]))
}