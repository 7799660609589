
import {reactive, toRefs } from 'vue'
import { 
    isValid, 
    drawBillList,
    drawBillManual,
    drawBillDetail
    } from '../../utils/api'
import { showMessage, geneIndex, formatTime,} from '@/utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '订单详情',
             confirmLoading: false,
             showDialog: false,
             showSelection: true,
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '订单ID', dataIndex: 'orderId', key: 'orderId'},
                //  {title: '彩民ID', dataIndex: 'consumerId', key: 'consumerId'},
                //  {title: '设备编号', dataIndex: 'deviceNo', key: 'deviceNo'},
                 {title: '店铺名', ellipsis: true, dataIndex: 'shopName', key: 'shopName'},
                 {title: '省', dataIndex: 'shopProvince', key: 'shopProvince'},
                 {title: '票面金额(元)', dataIndex: 'unitAmount', key: 'unitAmount'}, 
                 {title: '张数', dataIndex: 'totalNumber', key: 'totalNumber'},
                 {title: '总金额', dataIndex: 'totalAmount', key: 'totalAmount'},
                 {title: '订单状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '支付状态', dataIndex: 'payStatusText', key: 'payStatusText'},
                 {title: '存在退票', dataIndex: 'isExistRefundText', key: 'isExistRefundText'},
                 {title: '订单时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '操作',  dataIndex: 'manage', key: 'name',  slots: {customRender: 'actions'}},
             ],
             formItems: [
                 {type: 'input', label: '彩民ID', value: undefined},
                 {type: 'input', label: '订单ID', value: undefined},
                 {type: 'input', label: '设备编号', value: undefined},
                 {type: 'selector', label: '订单状态', key: 'value', options: [{label: '待付款', value: 0},{label: '出票中', value: 5} , {label: '已完成', value: 10}, {label: '已取消', value: 99}]},
                 {type: 'selector', label: '支付状态', key: 'value', options: [{label: '初始', value: 0}, {label: '等待结果', value: 1}, {label: '支付成功', value: 10}, {label: '支付失败', value: 40}, {label: '支付取消', value: 99}]},
                 {type: 'button', label: '搜索', eventName: 'search'},
             ],
             buttons: [
                //  {text: '出票', eventName: 'drawBill'},
                 {text: '详情', eventName: 'detail'},
             ],
             popForms: [
                 {label: '订单ID', value: '', type: 'input', name: 'orderId'},
                 {label: '彩民ID', value: '', type: 'input', name: 'consumerId'},
                 {label: '代理商ID', value: '', type: 'input', name: 'agentId'},
                 {label: '设备ID', value: '', type: 'input', name: 'deviceId'},
                //  {label: '设备编号', value: '', type: 'input', name: 'deviceNo'},
                 {label: '店铺ID', value: '', type: 'input', name: 'shopId'},
                 {label: '设备编号', value: '', type: 'input', name: 'deviceNo'},
                 {label: '店铺名', value: '', type: 'input', name: 'shopName'},
                 {label: '店铺-省', value: '', type: 'input', name: 'shopProvince'},
                 {label: '店铺-市', value: '', type: 'input', name: 'shopCity'},
                 {label: '店铺-区县', value: '', type: 'input', name: 'shopCounty'},
                 {label: '票面金额（元）', value: '', type: 'input', name: 'unitAmount'},
                 {label: '总金额（元）', value: '', type: 'input', name: 'totalAmount'},
                 {label: '总张数', value: '', type: 'input', name: 'totalNumber'},
                //  {label: '票面金额（元）', value: '', type: 'input', name: 'unitAmount'},
                 {label: '钱包支付金额（元）', value: '', type: 'input', name: 'payWalletAmount'},
                 {label: '自付金额（元）', value: '', type: 'input', name: 'payActualAmount'},
                 {label: '状态', value: '', type: 'input', name: 'statusText'},
                 {label: '第三方支付商Code', value: '', type: 'input', name: 'paymentProviderCode'},
                 {label: '第三方支付的订单id', value: '', type: 'input', name: 'paymentProviderOrderId'},
                 {label: '第三方支付账单号', value: '', type: 'input', name: 'paymentProviderBillNo'},
                 {label: '第三方支付平台订单日期', value: '', type: 'input', name: 'payBillDate'},
                 {label: '第三方支付结算日期', value: '', type: 'input', name: 'paySettleDate'},
                 {label: '支付状态', value: '', type: 'input', name: 'payStatusText'},
                 {label: '出票张数', value: '', type: 'input', name: 'successNumber'},
                 {label: '出票总金额（元）', value: '', type: 'input', name: 'successAmount'},
                 {label: '是否存在退票', value: '', type: 'input', name: 'isExistRefundText'},
                 {label: '退票张数', value: '', type: 'input', name: 'refundNumber'},
                 {label: '退票总金额（元）', value: '', type: 'input', name: 'refundAmount'},
                 {label: '钱包退款金额（元）', value: '', type: 'input', name: 'refundWalletAmount'},
                 {label: '钱包支付的退款状态', value: '', type: 'input', name: 'refundWalletStatusText'},
                //  {label: '退款金额（元）', value: '', type: 'input', name: 'refundAmount'},
                 {label: '自付金额的退款状态', value: '', type: 'input', name: 'refundActualStatusText'},
                 {label: '创建时间', value: '', type: 'input', name: 'createTime'},
                //  {label: '', value: '', type: 'input', name: ''},
                ],
            tempForms: [{label: '设备ID', value: '', type: 'input', name: 'status'},],
             forms: {timeout: ''},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             popFormsType: 0, // 0:详情 2:信息修改
        })

        // 退票状态
        function refundStatus(status: any) {
            // 文字对应数字
            const textMap = new Map()
            // 数字对应文字
            const numMap = new Map()
            const arr = [
                ['初始', '0'],
                ['退款中', '1'],
                ['退款成功', '10'],
                ['退款失败', '40'],
                ['取消', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[0], ele[1])
                numMap.set(ele[1], ele[0])
            })

            if(textMap.has(status)) {
                return textMap.get(status)
            } else {
                return numMap.get(status)
            }
        }

         // 订单状态Map
        function billStatus(status: any) {
            // 文字对应数字
            const textMap = new Map()
            // 数字对应文字
            const numMap = new Map()
            const arr = [
                ['待付款', '0'],
                ['出票中', '5'],
                ['已完成', '10'],
                ['已取消', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[0], ele[1])
                numMap.set(ele[1], ele[0])
            })

            if(textMap.has(status)) {
                return textMap.get(status)
            } else {
                return numMap.get(status)
            }
        }

        // 支付状态
        function payStatus(status: any) {
            const textMap = new Map()
            const letterMap = new Map()

            const arr = [
                ['初始', '0'],
                ['等待结果', '1'],
                ['支付成功', '10'],
                ['支付失败', '40'],
                ['支付取消', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[1], ele[0])
                letterMap.set(ele[0], ele[1])
            })
            if (textMap.has(status)) {
                return textMap.get(status)
            } else {
                return letterMap.get(status)
            }
        }
        function initTableText(data: any) {

            data.forEach((ele: any) => {
                ele.statusText = billStatus(ele.status)
                ele.payStatusText = payStatus(ele.payStatus)
                ele.refundStatusText = refundStatus(ele.refundStatus)
                ele.fundStatus = ele.isExistRefund == 0 ? '否' : '是'
            })
            return data
        }
        // 获取设备列表
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const forms = state.formItems
                const params = {
                    consumerId: forms[0].value,
                    orderId: forms[1].value,
                    deviceNo: forms[2].value,
                    status: forms[3].value,
                    payStatus: forms[4].value,
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                }
                const result = await drawBillList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = initTableText(result.data.records)
                    state.tableList.forEach((ele: any) => {
                        ele.isExistRefundText = ele.isExistRefund ? '是' : '否'
                        ele.createTime = formatTime(ele.createTime)
                    })
                    state.tableList = geneIndex(state.tableList, state.pageSize, state.currentPage)
                }
                if (isValid(result)) {
                    console.log(result)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        // 初始化显示详情
        function initDetailData(data: any) {
            const forms = state.popForms
            const ras = data.refundActualStatus 
            data.refundActualStatusText = ras == 0 ? '初始'
                                        : ras == 1 ? '退款中'
                                        : ras == 10 ? '退款成功'
                                        : ras == 40 ? '退款失败' : '暂无状态'

            const rws = data.refundWalletStatus
            data.refundWalletStatusText = rws == 0 ? '初始'
                                        : rws == 1 ? '退款中'
                                        : rws == 10 ? '退款成功'
                                        : rws == 40 ? '退款失败' : '暂无状态'

            const ps = data.payStatus
            data.payStatusText = ps == 0 ? '初始'
                                :ps == 1 ? '等待结果'
                                :ps == 10 ?'支付成功'
                                :ps == 40 ? '支付失败'
                                :ps == 99 ? '支付取消' : '暂无状态'
            const s = data.status 
            data.statusText = s == 0 ? '待付款'
                            : s == 5 ? '出票中'
                            : s == 10 ? '已完成'
                            : s == 99 ? '已取消' : '暂无状态'
            data.createTime = formatTime(data.createTime)
            data.isExistRefundText = data.isExistRefund ? '是' : '否'
            forms.forEach(ele => {
                ele.value = data[ele.name]
            })
        }
        // 查看设备详情
        async function detail(event: any) {
            try {
                const result = await drawBillDetail(event.orderId)
                if (isValid(result)) {
                    state.popFormsType = 0
                    state.tempForms = state.popForms
                    initDetailData(result.data)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 手动出票
        async function drawBill(event: any) {
            try {
                const result = await drawBillManual(event.id)
                if (result.code == 0) {
                    showMessage('出票成功')
                    loadData()
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        return {
            ...toRefs(state),
            detail,
            loadData,
            drawBill,
        }
    },
}
